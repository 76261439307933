import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, EndpointDisplay, KMCurve, PatientProfile, ReferencesBlock, NCCNcallout, TextBlock, ForestPlot, FeaturedStat } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import HRTNBC from '../../components/StudyDesign/__study-design/high-risk-tnbc';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-522 - Clinical Trial Results | HCP`,
    keywords: `keynote 522, keynote-522`,
    description: `Health care professionals may view findings from KEYNOTE-522, a clinical trial for patients with high-risk early-stage triple-negative breast cancer (TNBC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with high-risk early-stage triple-negative breast cancer (TNBC) in combination with chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠522","description":"1,174 patients with newly diagnosed, previously untreated high-risk early-stage TNBC were evaluated in a randomized, multicenter, double-blind, placebo-controlled trial","trialDesign":"37% reduction in the risk of an EFS event with KEYTRUDA + chemotherapyg/KEYTRUDA vs placebo + chemotherapyg/placebo (HRh=0.63; 95% CI, 0.48–0.82; Pi,j=0.00031)"}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"MedicalGuideline","guideline":"Pembrolizumab (KEYTRUDA): An NCCN Guideline®-recommended treatment option for eligible patients with high-risk early-stage (stage II-III) TNBC","recognizingAuthority":"NCCN® = National Comprehensive Cancer Network® "}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 27;
const jobCode = jobCodes[26].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced TNBC', url: '/efficacy/triple-negative-breast-cancer/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;522' },
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;522' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' },
    { label: 'Appropriate Patients' }
]

const pcrKeySecondaryCalloutFootnotes = [
    {
        label: "c.",
        text: "Based on the entire intention-to-treat population N=1,174 patients."
    }
    , {
        label: "d.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    , {
        label: "e.",
        text: "Based on a prespecified pCR interim analysis in patients (n=602), the pCR rate difference was statistically significant (<i>P</i>=0.00055 compared to a significance level of 0.003)."
    },
    {
        label: "f.",
        text: "Based on Miettinen and Nurminen method stratified by nodal status, tumor size, and choice of carboplatin."
    }
]

const keyChemoFootnotes = [
    {
        label: "g.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    {
        label: "h.",
        text: "HR based on stratified Cox regression model."
    },
    {
        label: "i.",
        text: "<i>P</i>-value based on a prespecified EFS interim analysis (compared to a significance level of 0.0052)."
    },
    {
        label: "j.",
        text: "<i>P</i>-value based on log-rank test stratified by nodal status, tumor size, and choice of carboplatin."
    },
    {
        label: "k.",
        text: "EFS was defined as the time from randomization to the first occurrence of any of the following events: progression of disease that precludes definitive surgery, local or distant recurrence, second primary malignancy, or death due to any cause."
    },
]

const NCCNcalloutData = {
    showBadge: false,
    bodyCopy: `
        <h4>Pembrolizumab (KEYTRUDA): An NCCN Guideline®-recommended treatment option for eligible patients with high-risk early-stage (stage II-III) TNBC<sup>11</sup></h4>
        <h5>CATEGORY 1 PREFERRED</h5>
        <p><b>Preoperative pembrolizumab (KEYTRUDA) in combination with carboplatin + paclitaxel, followed by preoperative pembrolizumab in combination with cyclophosphamide + doxorubicin or epirubicin, followed by adjuvant pembrolizumab<sup>11</sup></b></p>
    `,
};

const NCCNcalloutFootnotes = [
    {
        label: 'o.',
        text: 'High-risk criteria include Stage II-III TNBC.<sup>11</sup>'
    },
];

const NCCNcalloutDefinitions = `
    <p>Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>11</sup></p>
    <p>Preferred intervention = Intervention that is based on superior efficacy, safety, and evidence; and, when appropriate, affordability.<sup>11</sup></p>
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    <p>NCCN® = National Comprehensive Cancer Network®.</p>
`;

const NCCNcalloutReferences = [
    {
        label: '11.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Breast Cancer V.1.2025. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 31, 2025. To view the most recent and complete version of the guidelines, go online to NCCN.org.'
    }
];

const patientProfileRegina = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-regina.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Regina",
    diagnosis: "Clinical Prognostic Stage IIB TNBC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of breast cancer',
            emphasize: false,
        },
        {
            key: 'Physical exam',
            value: 'Palpable mass in left breast',
            emphasize: false,
        },
        {
            key: 'Mammogram',
            value: '2.4 cm spiculated mass lesion in upper outer quadrant of left breast corresponding to palpable mass lesion',
            emphasize: false,
        },
        {
            key: 'Ultrasound',
            value: '2.4 cm irregularly shaped solid mass lesion corresponding to mammographic abnormality; no suspicious axillary lymph nodes seen',
            emphasize: false,
        },
        {
            key: 'Biopsy',
            value: 'Grade 3 invasive ductal carcinoma <p>T2N0</p>',
            emphasize: false,
        },
        {
            key: 'Biomarker status',
            value: 'ER/PR&#8288;-&#8288;negative; HER2&#8288;-&#8288;negative',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'PET/CT scan',
            value: 'Negative for distant metastases',

        },
        {
            key: 'ECOG PS',
            value: '0',
        },
        {
            key: 'Age',
            value: '46',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Menopausal status',
            value: 'Pre-menopausal',
        },
        {
            key: 'BMI',
            value: '29 kg/m<sup>2</sup>',
        },
        {
            key: 'Family history',
            value: 'No family history of breast cancer',
        },
        // {
        //     key: '<b>T2N0</b>',
        //     value: '',
        // },
    ],
}

const pageReferences = [
    {
        label: "1.",
        text: "<i>Opdivo</i> [package insert]. Princeton, NJ: Bristol-Meyers Squibb Company; 2024."
    },
    {
        label: "2.",
        text: "<i>Libtayo</i> [package insert]. Tarrytown, NY: Regeneron Pharmaceuticals, Inc.; 2024. "
    },
    {
        label: "3.",
        text: "<i>Jemperli</i> [package insert]. Philadelphia, PA; GlaxoSmithKline LLC; 2024."
    },
    {
        label: "4.",
        text: "<i>Zynyz</i> [package insert]. Wilmington DE; Incyte Corporation; 2024."
    },
    {
        label: "5.",
        text: "<i>Loqtorzi</i>  [package insert]. Redwood City, CA; Coherus BioSciences; 2024."
    },
    {
        label: "6.",
        text: "<i>Tevimbra</i> Prescribing Information. San Mateo, CA: BeiGene USA, Inc.; 2024."
    },
    {
        label: "7.",
        text: "Opdivo Qvantig [package insert]. Princeton, NJ: Bristol-Meyers Squibb Company; 2024."
    },
    {
        label: "8.",
        text: "Schmid P, Cortes J, Dent R, et al. Overall survival with pembrolizumab in early-stage triple-negative breast cancer. <i>N Engl J Med</i>. 2024. Published online September 15, 2024. doi:10.1056/NEJMoa2409932"
    },
    {
        label: "9.",
        text: "Schmid P, Cortes J, Dent R, et al. Event-free survival with pembrolizumab in early triple-negative breast cancer. <i>N Engl J Med</i>. 2022;386(6):556-567.doi:10.1056/NEJMoa2112651"
    },
    {
        label: "10.",
        text: "Schmid P, Cortes J, Dent R, et al. Neoadjuvant pembrolizumab or placebo + chemotherapy followed by adjuvant pembrolizumab or placebo for high-risk early-stage triple-negative breast cancer: overall survival results from the phase 3 KEYNOTE-522 study. Presented at: European Society for Medical Oncology (ESMO) Congress; September 13-17, 2024; Barcelona, Spain."
    },
    {
        label: "11.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Breast Cancer V.1.2025. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 31, 2025. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
    {
        label: "12.",
        text: "American Cancer Society. Breast Cancer Signs and Symptoms. https://www.cancer.org/cancer/breast-cancer/about/breast-cancer-signs-and-symptoms.html. Updated January 14, 2022. Accessed September 18, 2024."
    }
];

const footnotesOne = [
    {
        label: "r.",
        text: "EFS was defined as the time from randomization to the first occurrence of any of the following events: progression of disease that precludes definitive surgery, local or distant recurrence, second primary malignancy, or death due to any cause."
    },
    {
        label: "s.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
];

const footnotesTwo = [
    {
        label: "p.",
        text: "In KEYNOTE&#8288;-&#8288;522, carboplatin/paclitaxel was used followed by AC or EC."
    }
];

const TextBlockFootnotes = [
    // {
    //     label: "k.",
    //     text: "According to the prespecified statistical criterion of alpha=0.00503, a statistically significant improvement in overall survival was observed in the pembrolizumab– chemotherapy group versus the placebo–chemotherapy group; thus, the overall survival end point was met and no formal testing will be performed at a later analysis."
    // },
    {
        label: "l.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    // {
    //     label: "m.",
    //     text: "OS data were not mature at the time of the First Interim Analysis (with a median follow-up of 24.1 months)."
    // },
    // {
    //     label: "n.",
    //     text: "The unstratified piecewise HR was 0.87 (95% CI, 0.57–1.32) before the 2-year follow-up and 0.51 (95% CI, 0.35–0.75) afterwards. The weighted average HR with weights of number of events before and after 2-year follow-up was 0.66."
    // },
    {
        label: "m.",
        text: "With 200 events (67.3% information fraction), the observed <em>P</em>-value crossed the prespecified boundary of 0.00503 (1-sided) at this interim analysis. Data cutoff date: March 22, 2024."
    },
];

const KMCurveFootnotes = [
    // {
    //     label: "p.",
    //     text: "Based on the small sample size and few events, results should be interpreted with caution. Data cutoff date: March 22, 2024."
    // },
    {
        label: "n.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                    <PageSection>
                        <AnchorLinks items={anchorLinkModelData}
                        />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">The First and Only Anti–PD&#8288;-&#8288;1 Indicated as Part of a Complete Regimen for High-Risk Early&#x2011;Stage TNBC<sup>1-7</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <HRTNBC />
                        </PageSection>
                        <PageSection bgColor="white" title={anchorLinkModelData[1].label}>
                            <PageSubSection title="pCR Rate at Prespecified Interim Analysis">
                                <EndpointDisplay title="pCR Rate (ypT0/Tis ypN0)<sup>c</sup>"
                                    limitation="KEYTRUDA + Chemotherapy<sup>d</sup> vs Placebo + Chemotherapy<sup>d</sup> in the Neoadjuvant Setting"
                                    cards={
                                        [
                                            {
                                                dataType: 'pCR',
                                                label: 'KEYTRUDA + chemotherapy<sup>d</sup>',
                                                rate: '63.0%',
                                                ci: '95% CI, 59.5–66.4',
                                                n: '494/784',
                                            },
                                            {
                                                dataType: 'pCR',
                                                label: 'Placebo + chemotherapy<sup>d</sup>',
                                                rate: '55.6%',
                                                ci: '95% CI, 50.6–60.6',
                                                n: '217/390',
                                            }
                                        ]
                                    }
                                />
                                <SecondaryCallout
                                    title="Estimated treatment difference: ~7.5% (95% CI, 1.6-13.4)<sup>e,f</sup> with KEYTRUDA + chemotherapy<sup>d</sup> vs placebo + chemotherapy<sup>d</sup>"
                                    alt=""
                                    footnotes={pcrKeySecondaryCalloutFootnotes}
                                    definitions="CI = confidence interval; AC = doxorubicin + cyclophosphamide; EC = epirubicin + cyclophosphamide."
                                />

                            </PageSubSection>
                            <PageSubSection title="Superior EFS With KEYTRUDA + Chemotherapy<sup>g</sup>/KEYTRUDA vs Placebo + Chemotherapy<sup>g</sup>/Placebo at IA4<sup>9</sup>">
                                <p>Event-free Survival (N=1,174)</p>
                                <KMCurve
                                    title="Kaplan-Meier Estimates of EFS in KEYNOTE&#8288;-&#8288;522<sup>h–j</sup>"
                                    limitation=""
                                    hr="HR<sup>h</sup>=0.63; 95% CI, 0.48–0.82; <i>P</i><sup> i,j</sup>=0.00031"
                                    image={() => <StaticImage
                                        src="../../assets/TNBC-KM-EFS-1-Chart-v4.png"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Estimates of Event-Free Survival in KEYNOTE-522" />}
                                />

                                <SecondaryCallout 
                                    title="37% reduction in the risk of an EFS event<sup>k</sup> with KEYTRUDA + chemotherapy<sup>g</sup>/KEYTRUDA vs placebo + chemotherapy<sup>g</sup>/placebo (HR<sup>h</sup>=0.63; 95% CI, 0.48–0.82; <i>P</i><sup>i,j</sup>=0.00031)"
                                    alt=""
                                    definitions=""
                                />

                                {/*<FeaturedStat
                                    statistic="37%"
                                    title="Reduction in the risk of an EFS event<sup>k</sup> with KEYTRUDA + chemotherapy<sup>g</sup>/KEYTRUDA vs placebo + chemotherapy<sup>g</sup>/placebo"
                                    hr="HR<sup>h</sup>=0.63 (95% CI, 0.48–0.82; <i>P</i><sup>i,j</sup>=0.00031)"
                                />*/}
                                
                                <SecondaryCallout
                                    title="The number of patients with an event was 123/784 (16%) with KEYTRUDA + chemotherapy<sup>g</sup>/KEYTRUDA vs 93/390 (24%) with placebo + chemotherapy<sup>g</sup>/placebo"
                                    alt=""
                                    footnotes={keyChemoFootnotes}
                                    definitions="HR = hazard ratio. IA4 = fourth interim analysis."
                                />
                            </PageSubSection>

                            <PageSubSection title="KEYNOTE-522: OS at Prespecified IA7 (Median Follow-Up Time: 75.1 Months)<sup>8,10</sup>">
                                <TextBlock><p>Based on the prespecified plan, the observed result for patients who received KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs placebo + chemotherapy<sup>l</sup>/placebo crossed the threshold required to demonstrate a statistically significant improvement compared with placebo.</p></TextBlock>
                                <TextBlock>
                                    <h4>Kaplan-Meier Estimates of OS in ITT Population<sup>10</sup></h4>
                                    {/* <p>Median Time From Randomization to Data Cutoff: 75.1 Months (Range: 65.9–84.0)<sup>l</sup></p> */}
                                </TextBlock>

                                <KMCurve
                                    image={() => <StaticImage
                                        src="../../assets/TNBC-KM-EFS-v2.png"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Estimates of OS in ITT Population in KEYNOTE-522" />
                                    }
                                />

                                {/*
                                <SecondaryCallout 
                                    title="34% reduction in the risk of death with KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs placebo + chemotherapy<sup>l</sup>/placebo (HR=0.66; 95% CI, 0.50-0.87; <i>P</i>=0.00150<sup>m</sup>)"
                                    alt=""
                                    definitions=""
                                />

                                <FeaturedStat
                                    statistic="34%"
                                    title="Reduction in the risk of death with KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs placebo + chemotherapy<sup>l</sup>/placebo"
                                    hr="HR=0.66 (95% CI, 0.50-0.87; <i>P</i>=0.00150<sup>m</sup>)"
                                />
                                */}

                                <TextBlock>
                                    <p>34% reduction in the risk of death with KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs placebo + chemotherapy<sup>l</sup>/placebo (HR=0.66; 95% CI, 0.50-0.87; <i>P</i>=0.00150<sup>m</sup>)</p>
                                    <p>At the protocol prespecified IA4 interim analysis of OS, OS data were not mature with 45% of the required events for the final analysis.</p>
                                    <p>The number of patients with an event was 115/784 (14.7%) with KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs 85/390 (21.8%) with placebo + chemotherapy<sup>l</sup>/placebo</p>
                                </TextBlock>

                                <TextBlock
                                    footnotes={TextBlockFootnotes}
                                    definitions="IA7 = seventh interim analysis; ITT = intent-to-treat."
                                >
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor='white'>
                            {/* <TextBlock>
                                <h4>KEYNOTE-522: OS Subgroups Median Follow-Up Time: 75.1 Months<sup>6,n</sup></h4>
                            </TextBlock> */}
                            <ForestPlot
                                title="KEYNOTE-522: OS in Select Subgroups at IA7 (Median Follow-Up Time: 75.1 Months)<sup>8,10</sup>"
                                chartAttribution="For overall population and PD-L1 subgroups, analyses based on Cox regression model with Efron's method of tie handling with treatment as a covariate and stratified by nodal status (positive vs negative), tumor size (T1/T2 vs T3/T4), and frequency of carboplatin administration (once weekly vs once every 3 weeks); for other subgroups, analysis based on unstratified Cox model."
                                footnotes={KMCurveFootnotes}
                                definitions="CPS = combined positive score."
                                limitation="<b class='bold'>LIMITATIONS:</b> KEYNOTE&#8288;-&#8288;522 was not powered to detect differences in the treatment effect in these subgroups, and no statistical testing was planned for this analysis; therefore, no conclusions can be drawn. Results from exploratory subgroup analyses should be interpreted with caution because of the modest patient numbers and potential imbalances in baseline characteristics within the subgroups."
                                image={() => <StaticImage
                                    src="../../assets/subgroup-chart-tnbc.png"
                                    placeholder="blurred"
                                    alt="OS in Select Subgroup Analysis at IA7 in KEYNOTE-522" />
                                }
                            />
                        </PageSection>



                        <PageSection bgColor="white" title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                //footnotes={NCCNcalloutFootnotes} 
                                definitions={NCCNcalloutDefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Hypothetical Patient Based on Those Studied in KEYNOTE&#8288;-&#8288;522<sup>11,12</sup>">
                                <TextBlock>
                                    <b>Patients May be Eligible for a Regimen Including KEYTRUDA Regardless of Tumor PD&#8288;-&#8288;L1 Expression</b>
                                </TextBlock>
                                <PatientProfile {...patientProfileRegina} definitions="BMI = body mass index; CT = computed tomography; ER = estrogen receptor; HER2 = human epidermal growth factor receptor 2; PET = positron emission tomography; PR = progesterone receptor." />

                                <SecondaryCallout
                                    title="Start with KEYTRUDA in combination with chemotherapy in the neoadjuvant setting. Then stay with KEYTRUDA as a single agent in the adjuvant setting after surgery."
                                    alt=""
                                    //footnotes={footnotesTwo}
                                />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    )
};

export default Page;
